import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import { Box, Heading, Button } from 'rebass';
import CardEditorial from 'components/CardEditorial';
import testData from 'components/CardEditorial/test.data';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "ui-molecules"
    }}>{`UI: Molecules`}</h1>
    <p>{`Molecules combine groups of atoms to form more complex functionality. You may be tempted to refer to these as "blocks" which contain "elements", which may be accurate for many of the molecules below, but we're avoiding that language to reinforce the atomic design terminology, and to allow for molecules that aren't necessarily clustured together visually.`}</p>
    <p>{`We have not started designing these yet. Screenshots from Waterworld have been inserted as placeholders.`}</p>
    <h2 {...{
      "id": "accordion"
    }}>{`Accordion`}</h2>
    <p>{`Collapsable block of content.`}</p>
    <img src={require('../Screenshots/accordion.png')} className="screenshot" />
    <p><strong parentName="p">{`Use this for:`}</strong></p>
    <ul>
      <li parentName="ul">{`Hiding away content without requiring a new page`}</li>
    </ul>
    <h2 {...{
      "id": "breadcrumb"
    }}>{`Breadcrumb`}</h2>
    <img src={require('../Screenshots/breadcrumb.png')} className="screenshot" />
    <h2 {...{
      "id": "card"
    }}>{`Card`}</h2>
    <Playground __position={2} __code={'<CardEditorial\n  image={testData.featuredImage.url}\n  title={testData.title}\n  teaser={testData.teaser}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Box,
      Heading,
      Button,
      CardEditorial,
      testData
    }} mdxType="Playground">
    <CardEditorial image={testData.featuredImage.url} title={testData.title} teaser={testData.teaser} mdxType="CardEditorial" />
    </Playground>
    <p><strong parentName="p">{`Use this for:`}</strong></p>
    <ul>
      <li parentName="ul">{`A teaser link to another page`}</li>
      <li parentName="ul">{`Condensed information about a film`}</li>
    </ul>
    <h2 {...{
      "id": "date-selector"
    }}>{`Date selector`}</h2>
    <img src={require('../Screenshots/calendar.png')} className="screenshot" />
    <h2 {...{
      "id": "featured-content-teaser"
    }}>{`Featured Content Teaser`}</h2>
    <img src={require('../Screenshots/featured-content-teaser.png')} className="screenshot" />
    <h2 {...{
      "id": "film-poster"
    }}>{`Film Poster`}</h2>
    <p>{`Used to toggle fitler between two options.`}</p>
    <img src={require('../Screenshots/film-poster.png')} className="screenshot" />
    <h2 {...{
      "id": "filter-radio"
    }}>{`Filter: Radio`}</h2>
    <p>{`Used to toggle fitler between two options.`}</p>
    <img src={require('../Screenshots/filter-radio.png')} className="screenshot" />
    <h2 {...{
      "id": "filter-search"
    }}>{`Filter: Search`}</h2>
    <img src={require('../Screenshots/filter-search.png')} className="screenshot" />
    <h2 {...{
      "id": "lightbox"
    }}>{`Lightbox`}</h2>
    <img src={require('../Screenshots/lightbox.png')} className="screenshot" />
    <h2 {...{
      "id": "navigation-primary"
    }}>{`Navigation: Primary`}</h2>
    <p>{`Cannot be shown inside docz, due to its dependancy on the router.`}</p>
    <h2 {...{
      "id": "search"
    }}>{`Search`}</h2>
    <img src={require('../Screenshots/search.png')} className="screenshot" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      