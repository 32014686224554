// {
//     basic(id: "7i6B6GK7GE2yc2kGw2UKYs", locale: "en") {
//       title
//       teaser
//       featuredImage {
//         title
//         description
//         contentType
//         fileName
//         size
//         url
//         width
//         height
//       }
//     }
//   }
export default {
    "title": "Restored David Lynch classics",
    "teaser": "This text will only appear on cards and other teasers.",
    "featuredImage": {
        "title": "Still 2",
        "description": "Blade Runner",
        "url": "https://images.ctfassets.net/2s9ec3izfl05/9UCY27RgByeOieC2scy66/d07f5764fcef4ded09df6fd7c15b0ba1/blade2_0.jpg"
    },
    "slug": "restored-david-lynch-classics"
}